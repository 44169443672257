import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popCodexFieldWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  props: {
    rewards: {
      type: Number,
      default: 0
    }
  },
  methods: {}
};