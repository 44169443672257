import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    luckyBoxList: [],
    luckyBoxInfo:{},
    luckyBoxItems:[],
    luckyBoxLogs:[],
    luckyBoxLogHasMore : false,
    luckyBoxLogLoading : false,
    selectTab:-1,
    luckyBoxyLogCondition:{
      lotteryId:"",
      page:1,
      limit:20
    }
  },
  mutations: {
    setLuckyBoxList: (state, payload) => {
      state.luckyBoxList = payload;
    },
    setLuckyBoxInfo: (state, payload) => {
      state.luckyBoxInfo = payload;
    },
    setLuckyBoxItems: (state, payload) => {
      state.luckyItems = payload;
    },
    setLuckyBoxLogs: (state, payload) => {
      state.luckyLogs = payload;
    },
    setLuckyBoxLogCondition:(state, payload) => {
      state.luckyLogCondition = payload
    },
    setLuckyBoxLogLoading:(state, payload) => {
        state.luckyLogLoading = payload
    },
    setLuckyBoxLogHasMore:(state, payload) => {
        state.luckyLogHasMore = payload
    },
    appendLuckyBoxLog:(state, payload) =>{
        state.luckyLogs = state.luckyLogs.concat(payload)
    },
    setSelectTab:(state, payload) => {
      state.selectTab = payload
  },
  },
  actions: {
    getLuckyBoxList:async ({ state, commit ,dispatch}) => {
        const rs = await Vue.prototype.$http.post("/lucky/box/list",{});
        vailcode(rs, () => {
          if(state.selectTab==-1 && rs.data.length > 0){
            commit("setSelectTab", 0);
          }
          commit("setLuckyBoxList", rs.data);
        });
    },
    getLuckyBoxInfo:async ({ state, commit },boxId) => {
      const rs = await Vue.prototype.$http.post("/lucky/box/info",{boxId});
      vailcode(rs, () => {
        commit("setLuckyBoxInfo", rs.data);
      });
  },
  },
  getters: {},
};
