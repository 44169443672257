var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.images ? _c('div', {
    staticClass: "carousel-container",
    on: {
      "touchstart": _vm.startTouch,
      "touchmove": _vm.moveTouch,
      "touchend": _vm.endTouch
    }
  }, [_c('div', {
    staticClass: "carousel-wrapper",
    style: {
      transform: `translateX(-${_vm.currentIndex * 100}%)`
    }
  }, _vm._l(_vm.images, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "carousel-item",
      style: {
        height: _vm.height + 'px',
        backgroundImage: `url(${image.url})`
      },
      on: {
        "click": function ($event) {
          return _vm.openUrl(image.link, image.link_type);
        }
      }
    });
  }), 0), _vm.showBtn ? _c('button', {
    staticClass: "carousel-button prev",
    on: {
      "click": _vm.prevImage
    }
  }, [_vm._v("‹")]) : _vm._e(), _vm.showBtn ? _c('button', {
    staticClass: "carousel-button next",
    on: {
      "click": _vm.nextImage
    }
  }, [_vm._v("›")]) : _vm._e()]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };