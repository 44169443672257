var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" How to play ")]), _c('div', {
    staticClass: "energy__brief"
  }, [_vm._v(" 1.After the countdown ends, the box with the least number of purchases will be the winning box. ")]), _c('div', {
    staticClass: "energy__brief"
  }, [_vm._v(" 2.In the event that multiple boxes have the same number of purchases, the last box purchased will be designated as the winner. ")]), _c('div', {
    staticClass: "energy__brief"
  }, [_vm._v(" 3.The winners will share 80% of the total prize pool, with the prize distributed according to the number of boxes purchased. ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };