var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "upgold__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/okwallet-logo.png`),
      "alt": ""
    }
  })]), !_vm.okxTonConnectUI || !_vm.okxTonConnectUI.connected ? _c('div', {
    staticClass: "upgold__title"
  }, [_vm._v(_vm._s(_vm.$lang("OKX Wallet Connect")))]) : _c('div', {
    staticClass: "upgold__title"
  }, [_vm._v(_vm._s(_vm.$lang("OKX Wallet Connected")))]), _c('div', {
    staticClass: "upgold__subtitle"
  }, [_vm._v(" address:"), _c('b', [_vm._v(_vm._s(_vm._f("fmt_address")(_vm.okxAddress, 10)))])]), _c('div', {
    staticClass: "btns"
  }, [!_vm.okxTonConnectUI || !_vm.okxTonConnectUI.connected ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.onOkxConnect
    }
  }, [_vm._v(_vm._s(_vm.$lang("Connect")))])] : [_c('div', {
    staticClass: "btn btn-dis",
    on: {
      "click": _vm.onOkxDisconnect
    }
  }, [_vm._v(_vm._s(_vm.$lang("Disconnect")))]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };