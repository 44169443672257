import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import { toNano } from "@ton/core";
export default {
  name: "popAirdropWithdraw",
  props: {},
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      isLoading: false,
      logInfo: {}
    };
  },
  watch: {
    "$parent.popRecordsInfoShow": {
      handler(bool) {
        if (bool) {
          this.isLoading = false;
          this.onLoadData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    cancelFn() {
      this.$emit("cancel");
    },
    async onLoadData() {
      this.isLoading = true;
      let rs = await this.$http.post("/lucky/box/log/info", {
        logId: this.$parent.logId
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.logInfo = rs.data;
      }
    }
  }
};