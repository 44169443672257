import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "bitgetWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      invitation_tip: "200,000 USDT airdrop has started, come to Tapcoins to claim it",
      rewardsInfo: [],
      recordList: [],
      totalPage: 0,
      nowPage: 0,
      scorllFlag: false,
      recordLoading: false,
      recordHasMore: true,
      popGetRewardTipShow: false,
      rewardData: {},
      isFlag: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popGetRewardTipShow = false;
    this.isFlag = true;
    window.removeEventListener('scroll', this.checkScroll);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    await this.getRewardInfo();
    await this.getRecordList(1);
    //window.addEventListener('scroll',this.checkScroll)
  },

  methods: {
    ...userVuex.mapMutations(['setUserData']),
    tonView() {
      this.WebApp.openLink('https://tonviewer.com/EQAebdctnt2DE6nSS3dkFHmdxHa4ml_Y8U_SShBYdGCYqj_9');
    },
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=${encodeURIComponent(this.invitation_tip)}&url=https://t.me/${this.setting.botname}?start=ref_${this.userData.ref_code}`);
    },
    async getRewardInfo() {
      let rs = await this.$http.post("/airdrop/extra/reward/info", {});
      if (rs.code == 0) {
        this.rewardsInfo = rs.data;
      }
    },
    async getRecordList(page) {
      this.recordLoading = true;
      let rs = await this.$http.post("/airdrop/invitation/reward/ranking", {
        limit: 10,
        airdropId: 1,
        page
      });
      this.recordLoading = false;
      if (rs.code == 0) {
        this.recordList = this.recordList.concat(rs.data.items);
        this.totalPage = rs.data.lastPage;
        this.nowPage = rs.data.currentPage;
        if (page == rs.data.lastPage) {
          this.recordHasMore = false;
        } else {
          this.recordHasMore = true;
        }
      }
    },
    checkScroll() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (!this.scorllFlag) {
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          // 接近底部时触发加载  
          this.scorllFlag = true;
          if (this.recordHasMore && !this.recordLoading) {
            let page = this.nowPage;
            this.getRecordList(page + 1);
          }
        }
      }
    },
    async onReceive(item, type, index) {
      if (!this.isFlag) true;
      if (item.received) return;
      if (type == 0) {
        this.rewardData.image = require(`@images/${this.theme}/icon-frens.png`);
        this.rewardData.title = `Invited ${item.count} friends`;
      } else {
        this.rewardData.image = "https://static.tapcoins.app/20240713/c02bee42-7541-40b0-9d77-2771af4c6ec7.png";
        this.rewardData.title = `${item.count} friends participated in the airdrop`;
      }
      this.rewardData.rewards = [{
        "image": require(`@images/${this.theme}/earn-key.png`),
        "num": item.stocks
      }];
      this.$loading.show();
      this.isFlag = false;
      let rs = await this.$http.post("/airdrop/extra/reward/receive", {
        type,
        index
      });
      this.$loading.hide();
      this.isFlag = true;
      if (rs.code == 0) {
        this.popGetRewardTipShow = true;
        item.received = true;
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
      } else {
        this.$toasted.error(rs.message);
      }
    }
  }
};