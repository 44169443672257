var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__title"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tap.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v("+" + _vm._s(_vm.rewards))])]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" You are qualified to claim " + _vm._s(_vm.setting.coin) + " rewards! ")]), _c('div', {
    staticClass: "energy__threetitle"
  }), _c('div', {
    staticClass: "btn-level",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Claim")))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };