import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const achievementsVuex = createNamespacedHelpers("achievements");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import { coinAnimFn } from "@utils/index";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFlag: true,
      loadingText: "..."
    };
  },
  methods: {
    ...achievementsVuex.mapActions(["taskUpgrade"]),
    async onSubmit() {
      if (!this.isFlag) return;
      this.isFlag = false;
      let t = setInterval(() => {
        if (this.loadingText.length < 3) {
          this.loadingText += ".";
        } else {
          this.loadingText = ".";
        }
      }, 500);
      await this.taskUpgrade([this.taskInfo.id, "", "", this.taskInfo.itemIndex, async rs => {
        this.isFlag = true;
        clearInterval(t);
        this.$parent.popEnergyShow = false;
      }, () => {
        this.isFlag = true;
        clearInterval(t);
        this.$parent.popEnergyShow = false;
      }]);
    },
    onCancel() {
      this.$parent.popEnergyShow = false;
    }
  }
};