import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import web3Config from "@Web3WalletConfig/config";
export default {
  name: "mMenu",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    web3Config() {
      return web3Config;
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};