import loadConfig from "@Web3WalletConfig/config.json";

export function throttle(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export function converDurationOrWidth(value, type = "duration") {
  const RADIO = 20;
  return type == "duration" ? value * RADIO : value / RADIO;
}

// 秒转时分秒
export const formatSeconds = function (time, isDecimal = false) {
  if (!time) return "00:00";
  const formatBit = function (val) {
    return val > 9 ? val : "0" + val;
  };
  let min = Math.floor(time % 3600);
  if (isDecimal) {
    let decimal = time.toString().split(".");
    let val = formatBit(Math.floor(min / 60)) + ":" + formatBit(parseInt(time) % 60) + `${decimal && decimal.length > 1 ? `:${decimal[1]}` : ":00"}`;
    return val;
  } else {
    let val = formatBit(Math.floor(min / 60)) + ":" + formatBit(parseInt(time) % 60);
    return val;
  }
};
/**
 * @description: 图片文件转字符串
 * @param {Blob|File} file 文件
 * @return {String}
 */
export function getImgStr(file) {
  return useBase64(file).promise.value;
}

/**
 * @description: 选择文件
 * @param {Object} options accept = '', capture = '', multiple = false
 * @return {Promise}
 */
export function selectFiles(options) {
  return new Promise((resolve) => {
    const { onChange, open } = useFileDialog(options);
    onChange((files) => {
      resolve(files);
    });
    open();
  });
}

/**
 * @description: 创建图片元素
 * @param {String} str 图片地址或者base64图片
 * @return {Promise} element 图片元素
 */
export function insertImgFile(str) {
  return new Promise((resolve) => {
    const imgEl = document.createElement("img");
    imgEl.src = str;
    // 插入页面
    document.body.appendChild(imgEl);
    imgEl.onload = () => {
      resolve(imgEl);
    };
  });
}

export function calcTextareaHeight(targetElement, minRows = 1, maxRows = null) {
  let hiddenTextarea;

  const HIDDEN_STYLE = `
height:0 !important;
visibility:hidden !important;
overflow:hidden !important;
position:absolute !important;
z-index:-1000 !important;
top:0 !important;
right:0 !important
`;
  const CONTEXT_STYLE = ["letter-spacing", "line-height", "padding-top", "padding-bottom", "font-family", "font-weight", "font-size", "text-rendering", "text-transform", "width", "text-indent", "padding-left", "padding-right", "border-width", "box-sizing"];
  const calculateNodeStyling = (targetElement) => {
    const style = window.getComputedStyle(targetElement);
    const boxSizing = style.getPropertyValue("box-sizing");
    const paddingSize = parseFloat(style.getPropertyValue("padding-bottom")) + parseFloat(style.getPropertyValue("padding-top"));
    const borderSize = parseFloat(style.getPropertyValue("border-bottom-width")) + parseFloat(style.getPropertyValue("border-top-width"));
    const contextStyle = CONTEXT_STYLE.map((name) => `${name}:${style.getPropertyValue(name)}`).join(";");
    return { contextStyle, paddingSize, borderSize, boxSizing };
  };

  if (!hiddenTextarea) {
    hiddenTextarea = document.createElement("textarea");
    document.body.appendChild(hiddenTextarea);
  }

  let { paddingSize, borderSize, boxSizing, contextStyle } = calculateNodeStyling(targetElement);

  hiddenTextarea.setAttribute("style", `${contextStyle};${HIDDEN_STYLE}`);
  hiddenTextarea.value = targetElement.value || targetElement.placeholder || "";

  let height = hiddenTextarea.scrollHeight;
  const result = {};

  if (boxSizing === "border-box") {
    height = height + borderSize;
  } else if (boxSizing === "content-box") {
    height = height - paddingSize;
  }

  hiddenTextarea.value = "";
  let singleRowHeight = hiddenTextarea.scrollHeight - paddingSize;

  if (minRows !== null) {
    let minHeight = singleRowHeight * minRows;
    if (boxSizing === "border-box") {
      minHeight = minHeight + paddingSize + borderSize;
    }
    height = Math.max(minHeight, height);
    result.minHeight = `${minHeight}px`;
  }
  if (maxRows !== null) {
    let maxHeight = singleRowHeight * maxRows;
    if (boxSizing === "border-box") {
      maxHeight = maxHeight + paddingSize + borderSize;
    }
    height = Math.min(maxHeight, height);
  }
  result.height = `${height}`;
  hiddenTextarea.parentNode && hiddenTextarea.parentNode.removeChild(hiddenTextarea);
  hiddenTextarea = null;
  return result;
}

export function canvasToImage(id) {
  // 获取 div 元素
  var div = document.getElementById(id);

  // 创建画布
  var canvas = document.createElement("canvas");
  canvas.width = div.offsetWidth;
  canvas.height = div.offsetHeight;

  // 获取画布上下文
  var ctx = canvas.getContext("2d");

  // 将 div 元素绘制到画布上
  ctx.drawImage(div, 0, 0);

  // 保存为图像
  var image = new Image();
  image.src = canvas.toDataURL();
  document.body.appendChild(image);
}
//深度拷贝
export function deepCopy(data, hash = new WeakMap()) {
  if (typeof data !== "object" || data === null) {
    throw new TypeError("传入参数不是对象");
  }
  // 判断传入的待拷贝对象的引用是否存在于hash中
  if (hash.has(data)) {
    return hash.get(data);
  }
  let newData = {};
  const dataKeys = Object.keys(data);
  dataKeys.forEach((value) => {
    const currentDataValue = data[value];
    // 基本数据类型的值和函数直接赋值拷贝
    if (typeof currentDataValue !== "object" || currentDataValue === null) {
      newData[value] = currentDataValue;
    } else if (Array.isArray(currentDataValue)) {
      // 实现数组的深拷贝
      newData[value] = [...currentDataValue];
    } else if (currentDataValue instanceof Set) {
      // 实现set数据的深拷贝
      newData[value] = new Set([...currentDataValue]);
    } else if (currentDataValue instanceof Map) {
      // 实现map数据的深拷贝
      newData[value] = new Map([...currentDataValue]);
    } else {
      // 将这个待拷贝对象的引用存于hash中
      hash.set(data, data);
      // 普通对象则递归赋值
      newData[value] = deepCopy(currentDataValue, hash);
    }
  });
  return newData;
}
//截取小数点后指定位数字符串
export function NumberSubstr(num, digits = 4) {
  let data = num + ""; //这一步是将data转换成string类型;
  data = data.substring(0, data.lastIndexOf(".") + digits + 1);
  return parseFloat(data);
}
export function getLevelName(l) {
  if(loadConfig.level[l]){
    return loadConfig.level[l]['name']
  }else{
    return loadConfig.level[loadConfig.level.length - 1]['name']
  }
}
/**
     * 大于1k会自动转换为1k digits是小数点保留位数
     * @param {*} num 
     * @param {*} digits 
     * @returns 
     */
export function nFormatter(num, digits) {
  const si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
          break;
      }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
export function coinAnimFn(params,that) {
  const _params = {
    containerRef: null, //出现金币区域dom 必传
    radius: 60, //出现金币区域范围
    numCoins: 30, //出现金币个数
    img: "icon-gold.png", //图片名 带后缀
    ref: null, //金币位移的终点dom 必传 String
    callback: null, //金币位移到终点后回调 必传
  };

  if (params == null || JSON.stringify(params) == "{}") {
    return;
  }
  if (!params.hasOwnProperty("ref")) {
    console.error("error: coinAnim ref?");
    return;
  } else {
    _params.ref = params.ref;
  }
  if (!params.hasOwnProperty("containerRef")) {
    console.error("error: coinAnim containerRef?");
    return;
  } else {
    _params.containerRef = params.containerRef;
  }
  if (!params.hasOwnProperty("callback") && typeof callback != "function") {
    console.error("error: coinAnim callback?");
    return;
  } else {
    _params.callback = params.callback;
  }

  if (params?.radius) {
    _params.radius = params.radius;
  }
  if (params?.numCoins) {
    _params.numCoins = params.numCoins;
  }
  if (params?.img) {
    _params.img = params.img;
  }

  console.log("_params", _params);
  let count = 0;
  let src = _params.img;//需指向币图片路径地址
  console.log("src", src);
  that.$nextTick(() => {
    const coinContainer = _params.containerRef;
    coinContainer.innerHTML = "";
    let coins = [];

    for (let i = 0; i < _params.numCoins; i++) {
      let coin = document.createElement("img");
      coin.src = src;
      coin.classList.add("getCoinAnim-coin");

      let angle = Math.random() * 2 * Math.PI;
      let r = Math.sqrt(Math.random()) * _params.radius;

      let x = _params.radius + r * Math.cos(angle) - 25;
      let y = _params.radius + r * Math.sin(angle) - 25;

      coin.style.left = `${x}px`;
      coin.style.top = `${y}px`;

      coinContainer.appendChild(coin);
      coins.push({ element: coin, finalX: x, finalY: y });
    }
    console.log("coins", coins);

    void coinContainer.offsetWidth;

    //setTimeout(() => {
      let endButton = _params.ref;
      let endButtonRect = endButton.getBoundingClientRect();
      let containerRect = coinContainer.getBoundingClientRect();

      let endX = endButtonRect.left - containerRect.left;
      let endY = endButtonRect.top - containerRect.top;
      console.log("endXendXendXendX", endX, endY);
      coins.forEach((coin, index) => {
        setTimeout(() => {
          coin.element.style.left = `${endX}px`;
          coin.element.style.top = `${endY}px`;
          if (index === coins.length - 1) {
            coin.element.addEventListener("transitionend", () => {
              count++;
              coinContainer.innerHTML = "";
              if (count == 1) _params.callback();
            });
          }
        }, index * 5);
      });
    //}, 500);
  });
}
