import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const mineVuex = createNamespacedHelpers("mine");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import { coinAnimFn } from "@utils/index";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...mineVuex.mapState(["lastUpgradeTime"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFlag: true,
      loadingText: "..."
    };
  },
  methods: {
    ...mineVuex.mapActions(["mineUpgrade", "mine"]),
    ...mineVuex.mapMutations(["setLastUpgradeTime"]),
    async onSubmit() {
      let time = parseInt(new Date().getTime() / 1000);
      if (this.lastUpgradeTime && time - 10 < Number(this.lastUpgradeTime)) {
        this.$toasted.error("Upgrade cooldown: 10s");
        return;
      }
      if (!this.isFlag) return;
      this.setLastUpgradeTime(time);
      this.isFlag = false;
      let t = setInterval(() => {
        if (this.loadingText.length < 3) {
          this.loadingText += ".";
        } else {
          this.loadingText = ".";
        }
      }, 500);
      await this.mineUpgrade([this.taskInfo.id, async rs => {
        this.isFlag = true;
        clearInterval(t);
        //this.$parent.taskInfo = rs.data.task_info;
        this.$parent.popEnergyShow = false;
        if (rs.lucky_coin && rs.lucky_coin > 0) {
          this.$parent.doSpecialsMine();
          this.$parent.awardCoin = rs.lucky_coin;
          this.$parent.popAwardShow = true;
        }
        this.$parent.refreshData();
        this.$nextTick(() => {
          coinAnimFn({
            containerRef: document.getElementById("destoryCoinContainerRef"),
            ref: document.getElementById("coinRef"),
            img: require(`@images/${this.theme}/lucky/tapcoins.png`),
            radius: 300,
            numCoins: 50,
            callback: () => {}
          }, this);
        });
      }, () => {
        this.isFlag = true;
        clearInterval(t);
        this.$parent.popEnergyShow = false;
      }]);
    }
  }
};