var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.taskInfo ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img",
    class: {
      enery__img__square: _vm.type == 1
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.taskInfo.image,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.taskInfo.title))]), _c('div', {
    staticClass: "energy__desc"
  }, [_vm._v(" " + _vm._s(_vm.taskInfo.description) + " ")]), _vm.taskInfo.status == 0 ? _c('div', {
    staticClass: "btn-level",
    on: {
      "click": _vm.onCancel
    }
  }, [_c('span', [_vm._v("coming soon")])]) : _vm.taskInfo.completed == 0 ? _c('div', {
    staticClass: "btn-level",
    style: _vm.isFlag ? {
      background: _vm.themeMainColor(_vm.theme).main
    } : 'color: #fff; background: gray; line-height:35px',
    attrs: {
      "enabled": _vm.isFlag
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm.isFlag ? _c('span', [_vm._v(_vm._s(_vm.$lang("Get")))]) : _c('span', [_vm._v("checking" + _vm._s(_vm.loadingText))])]) : _vm.taskInfo.completed == 1 ? _c('div', {
    staticClass: "btn-level",
    on: {
      "click": _vm.onCancel
    }
  }, [_c('span', [_vm._v("Close")])]) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };