/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./tapcoinIndex.vue?vue&type=template&id=400d8750&scoped=true"
import script from "./tapcoinIndex.vue?vue&type=script&lang=js"
export * from "./tapcoinIndex.vue?vue&type=script&lang=js"
import style0 from "./tapcoinIndex.vue?vue&type=style&index=0&id=400d8750&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400d8750",
  null
  
)

export default component.exports