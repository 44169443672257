import tonConfig from "@Web3WalletConfig/config.json";
import { OKXTonConnectUI } from "@okxconnect/ui";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popBindWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      okxTonConnectUI: null,
      okxAddress: ''
    };
  },
  watch: {
    "$parent.popBindOkxWalletShow": {
      handler(bool) {
        if (bool) {
          this.initOkx();
        }
      },
      deep: true,
      immediate: true
    },
    "okxTonConnectUI.connected": {
      handler(newValue, oldVal) {
        if (newValue) {
          this.okxAddress = toUserFriendlyAddress(this.okxTonConnectUI.account.address);
        } else {
          this.okxAddress = null;
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["getTaskList", "doTask"]),
    ...userVuex.mapMutations(['setUserData']),
    async initOkx() {
      this.okxTonConnectUI = new OKXTonConnectUI({
        dappMetaData: {
          name: this.setting.appname,
          icon: tonConfig.icon
        },
        buttonRootId: 'okx-ton-connect',
        actionsConfiguration: {
          returnStrategy: 'none'
        },
        uiPreferences: {
          theme: "SYSTEM"
        },
        language: 'en_US',
        restoreConnection: true
      });
    },
    async onOkxConnect() {
      await this.okxTonConnectUI.openModal();
    },
    async onOkxDisconnect() {
      await this.okxTonConnectUI.disconnect();
    },
    async onSubmit() {
      if (this.okxAddress) {
        this.doTask([this.$parent.itemData.id, "", this.okxAddress, data => {
          this.setTaskComplete({
            id: this.$parent.itemData.id
          });
          let newUserData = {
            ...this.userData,
            ...(data && data.userInfo ? data.userInfo : data)
          };
          this.setUserData(newUserData);
          this.loadTaskCount();
          this.$parent.popBindOkxWalletShow = false;
        }, rs => {
          this.error = rs && rs.message;
        }]);
      }
    }
  }
};