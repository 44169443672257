import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "avatar",
  computed: {
    ...userVuex.mapState(["userData"]),
    ...walletVuex.mapState(["theme"]),
    loadConfig() {
      return loadConfig;
    }
  },
  data() {
    const avatarList = [{
      "name": "Warrior on the Attack",
      "intro": "With a monkey partner on my head, I’m twice as fierce!"
    }, {
      "name": "Greedy Gourmet",
      "intro": "Holding a small fish snack, ready to conquer the world of treats!"
    }, {
      "name": "Rubber Duck Defender",
      "intro": "Saving the world, one rubber duck at a time!"
    }, {
      "name": "Captain Cat",
      "intro": "Eye patch, coins, and a whole lot of attitude—I’m the perfect pirate!"
    }, {
      "name": "Mummy Scientist",
      "intro": "Preparing to scare people or just keeping warm, who knows?"
    }];
    const backgroundList = [{
      "skin": "basic"
    }, {
      "skin": "basic1"
    }, {
      "skin": "basic2"
    }];
    const tab = [{
      label: "Avatar",
      value: "avatar"
    }, {
      label: "Background",
      value: "background"
    }];
    return {
      tab,
      selectTab: "avatar",
      selectAvatar: 1,
      isUseLoading: false,
      selectBackground: "basic",
      avatarList,
      backgroundList
    };
  },
  watch: {
    "userData.avatar_url": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.selectAvatar = newVal || 1;
      },
      deep: true,
      immediate: true
    },
    "userData.skin": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectBackground = newVal;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...userVuex.mapActions(["updateResultAvatar", ""]),
    onSelect(index) {
      this.selectAvatar = index;
    },
    onBgSelect(skin) {
      this.selectBackground = skin;
    },
    async onUse() {
      this.isUseLoading = true;
      const res = await this.updateResultAvatar({
        avatar: this.selectAvatar,
        skin: this.selectBackground
      });
      if (!res) {
        this.isUseLoading = false;
        return;
      }
      this.isUseLoading = false;
      this.$nextTick(() => {
        this.$router.push("/");
      });
    },
    onTab(item) {
      console.log("item", item);
      this.selectTab = item.value;
    }
  },
  mounted() {
    this.setWowAnim();
  }
};