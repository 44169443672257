var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Ranking")]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    staticClass: "pop-log-box"
  }, [_vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "pop-log-item"
    }, [_c('div', {
      staticClass: "row avatar"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color || ''
      }
    })], 1), _c('div', {
      staticClass: "row name"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "row amount"
    }, [_vm._v(_vm._s(_vm._f("nFormatter")(item.winning_amount || '0', 2)))]), _c('div', {
      staticClass: "row idx"
    }, [_vm._v("#" + _vm._s(index + 1))])]);
  }), _vm.isLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : _vm._e()], 2)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };