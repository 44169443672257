var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("My Records")]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "pop-log-box",
    on: {
      "scroll": _vm.checkScroll
    }
  }, [_c('div', {
    staticClass: "pop-log-item hd"
  }, [_c('div', {
    staticClass: "row time"
  }, [_vm._v("Prize draw time")]), _c('div', {
    staticClass: "row box"
  }, [_vm._v("Win")]), _c('div', {
    staticClass: "row pool"
  }, [_vm._v("Prize Pool")]), _c('div', {
    staticClass: "row opt"
  }, [_vm._v(" ")])]), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "pop-log-item",
      on: {
        "click": function ($event) {
          return _vm.onInfo(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "row time"
    }, [_vm._v(_vm._s(item.round.start_time_str))]), _c('div', {
      staticClass: "row box"
    }, [_vm._v(_vm._s(item.round.winning_item_name || '-'))]), _c('div', {
      staticClass: "row pool"
    }, [_vm._v(_vm._s(_vm._f("nFormatter")(item.round.buy_amount || 0, 2)))]), _c('div', {
      staticClass: "row opt cur"
    }, [_vm._v(">")])]);
  }), _vm.isLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : _vm._e()], 2)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };