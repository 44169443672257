import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      endX: 0,
      isDragging: false
    };
  },
  props: {
    images: {
      type: Array,
      default: []
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 200
    }
  },
  methods: {
    startTouch(e) {
      this.startX = e.touches[0].clientX;
      this.isDragging = true;
      clearInterval(this.carouselInterval);
    },
    moveTouch(e) {
      if (!this.isDragging) return;
      this.endX = e.touches[0].clientX;
    },
    endTouch() {
      if (!this.isDragging) return;
      this.isDragging = false;
      const diffX = this.endX - this.startX;
      if (diffX > 50) {
        this.prevImage();
      } else if (diffX < -50) {
        this.nextImage();
      } else {
        // 如果滑动距离不足，则根据当前自动播放状态决定是否继续播放  
        this.startAutoPlay();
      }
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    startAutoPlay() {
      this.carouselInterval = setInterval(this.nextImage, 3000); // 每3秒切换一次图片  
    },

    openUrl(link, type) {
      if (type == "link") {
        if (link.indexOf('t.me') !== -1) {
          this.WebApp.openTelegramLink(link);
        } else {
          this.WebApp.openLink(link);
        }
      } else if (type == 'page') {
        this.$router.push(link);
        return;
      } else {
        return;
      }
    }
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    clearInterval(this.carouselInterval);
  }
};