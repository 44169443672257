import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import cookie from "js-cookie";
export default {
  name: "popAirdripTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  watch: {
    "$parent.popEarnTipShow": {
      handler(bool) {
        if (bool) {}
      }
    }
  },
  data() {
    return {};
  },
  methods: {}
};