var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_vm.isLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : [_c('div', {
    staticClass: "pop__intro"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Prize draw time")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.logInfo.start_time_str))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Prize Pool")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.logInfo.buy_amount || 0, 2)))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Winning box")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.logInfo.winning_item_name || '-'))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("My Rewards")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.logInfo.user_winning_amount || 0, 2)))])])]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    staticClass: "pop-log-box"
  }, [_c('div', {
    staticClass: "pop-log-item hd"
  }, [_c('div', {
    staticClass: "row box"
  }, [_vm._v("BOX")]), _c('div', {
    staticClass: "row time"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "row pool"
  }, [_vm._v("My Purchase")])]), _vm._l(_vm.logInfo.items, function (item, index) {
    return _c('div', {
      staticClass: "pop-log-item"
    }, [_c('div', {
      staticClass: "row box"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "row time"
    }, [_vm._v(_vm._s(item.buy_count))]), _c('div', {
      staticClass: "row pool"
    }, [_vm._v(_vm._s(item.user_buy_count || 0))])]);
  })], 2)])]], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };