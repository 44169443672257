var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-main"
  }, _vm._l(_vm.menu, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "menu-item",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item);
        }
      }
    }, [_c('div', {
      staticClass: "menu-item-main"
    }, [_vm.route == item.path ? _c('div', {
      staticClass: "menu-item-active",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/menu-active-bg.png`)}) center top/100% no-repeat`
      }
    }) : _vm._e(), _c('div', {
      staticClass: "menu-item__icon",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/${_vm.route == item.path ? item.icon + '-active' : item.icon}.png`)}) center/auto 100% no-repeat`
      }
    }), _c('div', {
      staticClass: "menu-item__label",
      style: {
        color: _vm.route == item.path ? '#fff' : '#9ba2c5'
      }
    }, [_vm._v(_vm._s(item.label))]), _vm.taskCount && item.path === '/earn' ? _c('span', [_vm._v(_vm._s(_vm.taskCount))]) : _vm._e(), item.path === '/airdrop' ? _c('span', {
      staticClass: "min"
    }) : _vm._e()])]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };