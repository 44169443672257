var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "achievements page",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "achievements"
    }
  }, [_c('div', {
    staticClass: "achievements-main"
  }, [_c('div', {
    staticClass: "left-menus"
  }, [_c('ul', _vm._l(_vm.categoryList, function (item, index) {
    return _c('li', {
      class: _vm.currentIndex == index && 'active',
      on: {
        "click": function ($event) {
          return _vm.scrollToSection(index, item.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": item.image
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)]), _c('div', {
    ref: "myScroll",
    staticClass: "right-subs",
    on: {
      "scroll": _vm.handleScroll
    }
  }, _vm._l(_vm.categoryList, function (item, index) {
    return _c('div', {
      ref: "sections",
      refInFor: true,
      attrs: {
        "id": 'a' + item.id
      }
    }, [_c('div', {
      staticClass: "desc"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('ul', _vm._l(item.sub, function (itm, idx) {
      return _c('li', {
        class: !itm.status && 'disable' || itm.completed && 'active',
        on: {
          "click": function ($event) {
            return _vm.openView(itm, index);
          }
        }
      }, [_c('div', {
        staticClass: "item-box"
      }, [_c('img', {
        attrs: {
          "src": itm.image
        }
      }), _c('div', {
        staticClass: "item-txt"
      }, [_vm._v(_vm._s(itm.title))])])]);
    }), 0)]);
  }), 0)]), _c('pop-achievements', {
    attrs: {
      "show": _vm.popEnergyShow,
      "taskInfo": _vm.selectItem
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };