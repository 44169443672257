var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "quest page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_vm.luckyBoxInfo.id ? _c('div', {
    staticClass: "quest-main"
  }, [_c('div', {
    staticClass: "quest-top"
  }, [_vm._v(" Crypto Quest ")]), _c('div', {
    staticClass: "quest-desc"
  }, [_vm._v(" Victory belongs to the few ")]), _c('div', {
    staticClass: "tab_main"
  }, _vm._l(_vm.luckyBoxList, function (item, index) {
    return _c('div', {
      staticClass: "tab_item",
      class: [item.status != 1 && 'dis', _vm.selectTab == index ? 'checked' : ''],
      on: {
        "click": function ($event) {
          return _vm.onTap(item, index);
        }
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$lang(item.title)) + " ")]);
  }), 0), _c('div', {
    staticClass: "box-detail"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v(" Current Prize Pool ")]), _c('div', {
    staticClass: "num"
  }, [_vm.luckyBoxInfo.icon ? _c('img', {
    attrs: {
      "src": _vm.luckyBoxInfo.icon
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.luckyBoxInfo?.round?.buy_amount || 0, 2)) + " ")]), _c('div', {
    staticClass: "brief"
  }, [_vm._v(" The box with the fewest purchases will be the winner ")])]), _c('div', {
    staticClass: "box-items",
    class: _vm.luckyBoxInfo.round.status == 2 && 'items-draw'
  }, [_c('div', {
    staticClass: "item-title"
  }, [_vm.luckyBoxInfo.round.status == 0 || _vm.luckyBoxInfo.round.status == 3 ? [_c('div', {
    staticClass: "t"
  }, [_vm._v(_vm._s(_vm._f("formatHour")(_vm.countDown)))]), _c('div', {
    staticClass: "sub-t"
  }, [_vm._v("Waiting for start")])] : _vm._e(), _vm.luckyBoxInfo.round.status == 1 ? [_c('div', {
    staticClass: "t"
  }, [_vm._v(_vm._s(_vm._f("formatHour")(_vm.countDown)))]), _c('div', {
    staticClass: "sub-t"
  }, [_vm._v("Countdown to purchase")])] : _vm._e(), _vm.luckyBoxInfo.round.status == 2 ? [_c('div', {
    staticClass: "t"
  }, [_vm._v(_vm._s(_vm._f("formatHour")(_vm.countDown)))]), _c('div', {
    staticClass: "sub-t"
  }, [_vm._v("Prize draw")])] : _vm._e()], 2), _c('div', {
    staticClass: "items"
  }, _vm._l(_vm.luckyBoxInfo.items, function (item, index) {
    return _c('div', {
      staticClass: "item",
      class: [_vm.selectItemIndex == index && 'checked', item.buy_count > 0 && 'checked-1'],
      on: {
        "click": function ($event) {
          _vm.selectItemIndex = index;
        }
      }
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/luckybox/box/${index + 1}${item.buy_count > 0 ? '1' : ''}.png`)
      }
    })]), item.buy_count ? _c('div', {
      staticClass: "bought"
    }, [_vm._v("Bought " + _vm._s(item.buy_count))]) : _vm._e()]);
  }), 0)]), _c('div', {
    staticClass: "purchase"
  }, [_vm.luckyBoxInfo.icon ? _c('img', {
    attrs: {
      "src": _vm.luckyBoxInfo.icon
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.luckyBoxInfo.price || 0, 2)) + " Per Purchase ")]), _c('div', {
    staticClass: "buy-btn",
    class: _vm.selectItemIndex == -1 || _vm.luckyBoxInfo.round.status != 1 || _vm.luckyBoxInfo.max_buy_count > 0 && _vm.luckyBoxInfo.max_buy_count <= _vm.luckyBoxInfo.user.round.user_buy_count ? 'buy-btn-dis' : '',
    on: {
      "click": function ($event) {
        return _vm.onBuy();
      }
    }
  }, [_vm._v(" BUY "), _c('div', {
    staticClass: "num"
  }, [_c('span', [_vm._v(_vm._s(_vm.luckyBoxInfo.user.round.user_buy_count || 0))]), _vm.luckyBoxInfo.max_buy_count ? [_vm._v(" / " + _vm._s(_vm.luckyBoxInfo.max_buy_count || 0) + " ")] : _vm._e()], 2)]), _c('div', {
    staticClass: "box-balance"
  }, [_c('div', {
    staticClass: "num"
  }, [_vm._v(" Balance "), _vm.luckyBoxInfo.icon ? _c('img', {
    attrs: {
      "src": _vm.luckyBoxInfo.icon
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.luckyBoxInfo.user.assets.balance || 0, 2)) + " ")]), _c('div', {
    staticClass: "tip",
    on: {
      "click": function ($event) {
        _vm.popTipShow = true;
      }
    }
  }, [_vm._v(" How to play "), _c('svg-icon', {
    attrs: {
      "name": "icon-quest"
    }
  })], 1)]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-1",
    on: {
      "click": function ($event) {
        return _vm.onRanking();
      }
    }
  }, [_vm._v("Ranking")]), _c('div', {
    staticClass: "btn btn-2",
    on: {
      "click": function ($event) {
        return _vm.onRecords();
      }
    }
  }, [_vm._v("My Records")])])]) : _vm._e()]), _c('pop-luckybox-tip', {
    attrs: {
      "show": _vm.popTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popTipShow = $event;
      }
    }
  }), _c('pop-luckybox-ranking', {
    attrs: {
      "show": _vm.popRankingShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRankingShow = $event;
      }
    }
  }), _c('pop-luckybox-records', {
    attrs: {
      "show": _vm.popRecordsShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRecordsShow = $event;
      }
    }
  }), _c('pop-luckybox-records-info', {
    attrs: {
      "show": _vm.popRecordsInfoShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRecordsInfoShow = $event;
      }
    }
  }), _c('pop-reward-result', {
    attrs: {
      "show": _vm.isPopRewardResultShow,
      "select-row": _vm.selectRow,
      "showBtn": false,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopRewardResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };