import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const appcenterVuex = createNamespacedHelpers("appcenter");
import { coinAnimFn } from "@utils/index";
export default {
  name: "popAppcenterEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {
        //if(newVal && oldVal && newVal.id != oldVal.id){
        this.lastTime = 0;
        clearInterval(this.lastTimer);
        this.showError = false;
        this.lastTimeStr = '';
        this.isFlag = true;
        this.lastTime = newVal.countdown;
        this.countDown();
        //}
      },

      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFlag: true,
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: '',
      showError: false
    };
  },
  mounted() {},
  methods: {
    ...appcenterVuex.mapActions(["taskUpgrade"]),
    async onSubmit() {
      if (this.taskInfo.completed == 1 && this.lastTime > 0) {
        return;
      }
      if (!this.isFlag) return;
      this.isFlag = false;
      this.showError = false;
      await this.taskUpgrade([this.taskInfo.id, async rs => {
        this.isFlag = true;
        this.$parent.popEnergyShow = false;
        this.$parent.refreshData();
        if (rs.data && rs.data.countdown) {
          this.lastTime = rs.data.countdown;
          this.countDown();
        }
        this.$nextTick(() => {
          coinAnimFn({
            containerRef: document.getElementById("destoryCoinContainerRef"),
            ref: document.getElementById("coinRef"),
            img: require(`@images/${this.theme}/tickets.png`),
            radius: 300,
            numCoins: 50,
            callback: () => {}
          }, this);
        });
      }, rs => {
        this.isFlag = true;
        this.showError = true;
      }]);
    },
    openUrl(item) {
      let link = item.url;
      if (link.indexOf('t.me') !== -1) {
        this.WebApp.openTelegramLink(link);
      } else {
        this.WebApp.openLink(link);
      }
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          this.isFlag = true;
          clearInterval(this.lastTimer);
        }
      }, 1000);
    }
  }
};