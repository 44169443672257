var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "bitgetwallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-logo"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/logo.png`),
      "width": "50px"
    }
  }), _c('div', {
    staticClass: "logo-x"
  }, [_vm._v(" × ")]), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/bnb_logo.png`),
      "width": "50px"
    }
  })]), _c('div', {
    staticClass: "top-box-title"
  }, [_vm._v(" " + _vm._s(_vm.setting.coin) + "_BNB Chain Rise & Prize ")])]), _c('div', {
    staticClass: "time-box"
  }, [_c('div', {
    staticClass: "time-box-left"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/okxwallet-live.png`),
      "alt": ""
    }
  }), _c('div', [_vm._v("Live Now")])])]), _vm._l(_vm.subTaskList, function (item, index) {
    return _c('div', {
      staticClass: "content-box"
    }, [item.title == 'CodexFieldWalletCheckIn' ? [_c('div', {
      staticClass: "content-box-title"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/daily_icon.png`)
      }
    }), _vm._v(" Daily Check-in ")]), _c('div', {
      staticClass: "content-box-desc"
    }, [_vm._v(" You can get a reward every day after Chenk-in ")]), _c('div', {
      staticClass: "content-box-reward"
    }, [_c('div', {
      staticClass: "content-box-reward-text"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/tap.png`),
        "alt": ""
      }
    }), _vm._v(" +" + _vm._s(_vm._f("toThousands")(100)) + " Per day ")])]), _c('div', {
      staticClass: "content-box-btns"
    }, [item.completed == 0 ? _c('div', {
      staticClass: "content-box-btn",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item, 'daily');
        }
      }
    }, [_c('div', {
      staticClass: "content-box-btn-text"
    }, [_vm._v(" GO ")])]) : _c('div', {
      staticClass: "content-box-btn content-box-btn-gray"
    }, [_c('div', {
      staticClass: "content-box-btn-text"
    }, [_vm._v(" Next time " + _vm._s(_vm.lastTimeStr) + " ")])]), item.completed == 0 ? _c('div', {
      staticClass: "content-box-btn content-box-btn-check",
      on: {
        "click": function ($event) {
          return _vm.subTaskComplete(item);
        }
      }
    }, [_c('div', {
      staticClass: "content-box-btn-text"
    }, [_vm._v(" Check "), item.loading && !_vm.isFlag ? _c('span', [_vm._v(" " + _vm._s(_vm.loadingText) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "invite-arrow"
    }, [item.completed == 1 ? _c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    }) : _vm._e()], 1)]) : _vm._e()])] : _vm._e(), item.title == 'CodexFieldWalletStatus' ? [item.title == 'CodexFieldWalletStatus' ? _c('div', {
      staticClass: "content-box-title"
    }, [_vm._v(" BNB Chain Rise & Prize ")]) : _vm._e(), _c('div', {
      staticClass: "content-box-desc"
    }, [_vm._v(" BNB Chain is giving away exciting treasures for Telegram explorers! Dive deeper, try elite dApps on TG with the CodexField Wallet, and win your big rewards! ")]), _c('div', {
      staticClass: "content-box-reward"
    }, [_c('div', {
      staticClass: "content-box-reward-text"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/tap.png`),
        "alt": ""
      }
    }), _vm._v(" +" + _vm._s(_vm._f("toThousands")(1000)) + " ")])]), _c('div', {
      staticClass: "content-box-btns"
    }, [_c('div', {
      staticClass: "content-box-btn",
      on: {
        "click": function ($event) {
          return _vm.onSkip(item, 'status');
        }
      }
    }, [_c('div', {
      staticClass: "content-box-btn-text"
    }, [_vm._v(" GO ")]), _c('div', {
      staticClass: "invite-arrow"
    }, [item.completed == 1 ? _c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    }) : _vm._e()], 1)]), item.completed == 0 ? _c('div', {
      staticClass: "content-box-btn content-box-btn-check",
      on: {
        "click": function ($event) {
          return _vm.subTaskComplete(item);
        }
      }
    }, [_c('div', {
      staticClass: "content-box-btn-text"
    }, [_vm._v(" Check "), item.loading && !_vm.isFlag ? _c('span', [_vm._v(" " + _vm._s(_vm.loadingText) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "invite-arrow"
    }, [item.completed == 1 ? _c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    }) : _vm._e()], 1)]) : _vm._e()])] : _vm._e()], 2);
  }), _c('pop-codex-field-wallet', {
    attrs: {
      "rewards": _vm.rewards,
      "show": _vm.popCodeFieldWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popCodeFieldWalletShow = $event;
      }
    }
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };