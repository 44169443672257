import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cityHead"
  }, [_c('div', {
    staticClass: "cityHead-main"
  }, [_c('div', {
    staticClass: "header wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "header-main"
  }, [_vm.userData ? _c('div', {
    staticClass: "header-avatar",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/avatar/avatar-${_vm.userData.avatar_url || 1}.png`)}) center top/120% auto no-repeat`
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/avatar');
      }
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-item wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "menu-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("Profit per hour")))]), _c('div', {
    staticClass: "menu-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/perHour.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm._f("toThousands")(_vm.hourEearnings)) + " ")])])]), _c('div', {
    staticClass: "btns"
  }, [_vm.setting.socials.channel ? _c('div', {
    staticClass: "btn-more wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/wallet');
      }
    }
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require(`@images/${_vm.theme}/new/icon-wallet.png`),
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "btn-more wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require(`@images/${_vm.theme}/new/icon-menu.png`),
      "alt": ""
    },
    on: {
      "click": () => _vm.popMenuShow = true
    }
  }), _vm.popMenuShow ? _c('div', {
    staticClass: "pop-more",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": () => _vm.popMenuShow = false
    }
  }), _c('div', {
    staticClass: "pop-more-main"
  }, [_c('div', {
    staticClass: "pop-more-item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip(_vm.userData.user_group_id <= 0 ? 'join' : 'squad', _vm.userData.user_group_id);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/icon-group.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v("Squad")])]), _c('div', {
    staticClass: "pop-more-item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('pph');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/icon-profit.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v("Profit per hour")])]), _c('div', {
    staticClass: "pop-more-item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkip('rank');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/icon-ranking.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v("Ranking")])])])]) : _vm._e()])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };